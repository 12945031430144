import React from 'react';
import {
	TextInput,
	PasswordInput,
	BooleanInput
} from 'react-admin';

import Create from '../common/Create';
import SimpleForm from '../common/SimpleForm';


const UserCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<TextInput source="email" />
			<TextInput source="firstname" />
			<TextInput source="lastname" />
			<PasswordInput source="password" />
			<BooleanInput source="can_manage_users" />
		</SimpleForm>
	</Create>
);

export default UserCreate;